import http from '@/network/http'

export default {
  getAll(params) {
    return http.get('/api/avatar', params)
  },
  getPage(currPage, pageCount, params) {
    return http.get(`/api/avatar/page/${currPage}/${pageCount}`, params)
  },
  getOne(id) {
    return http.get(`/api/avatar/${id}`)
  },
  create(params) {
    return http.post('/api/avatar', params)
  },
  update(params) {
    return http.put(`/api/avatar/${params.id}`, params)
  },
  delete(id) {
    return http.delete(`/api/avatar/${id}`)
  },
  batchCreate(params) {
    return http.post(`/api/avatar/batch`, params)
  },
}
