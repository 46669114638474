import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueProgressBar from 'vue-progressbar'
import './plugins/element.js'
import mixin from './mixins'
import './styles/common.scss'

Vue.config.productionTip = false

const options = {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px',
}
Vue.use(VueProgressBar, options)

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
