<template>
  <div id="container" class="d-flex justify-center align-center">
    <div id="titleContainer">
      <div id="bigTitle">
        <div class="char">熊</div>
        <div class="char">猫</div>
        <div class="char">管</div>
        <div class="char">理</div>
        <div class="char">平</div>
        <div class="char">台</div>
      </div>
      <div id="secTitle">欢迎使用管理平台</div>
    </div>
    <div class="loginBox">
      <img src="@/assets/logo.png" @click="adminLogin = !adminLogin" />
      <div id="tips" class="title text-center font-weight-bold">请 登 录</div>
      <el-form ref="loginForm" :model="form" :rules="rules" label-width="0" class="loginForm">
        <el-form-item prop="loginName">
          <el-input v-model="form.loginName" prefix-icon="el-icon-user-solid" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            prop="password"
            prefix-icon="el-icon-lock"
            show-password
            placeholder="请输入密码"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="remember">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" :loading="loading" @click="login" v-if="adminLogin">管理员登陆</el-button>
          <el-button type="primary" :loading="loading" @click="login" v-else>登陆</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { LoginService } from '@/network'

export default {
  data() {
    return {
      form: {
        loginName: '',
        password: '',
        admin: false,
      },
      adminLogin: false,
      remember: false,
      loading: false,
      rules: {
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' },
        ],
      },
    }
  },
  mounted() {
    this.remember = window.localStorage.getItem('remember') ? true : false
    if (this.remember) {
      this.form.loginName = window.localStorage.getItem('loginName')
      this.form.password = window.localStorage.getItem('password')
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.form.admin = this.adminLogin
          LoginService.login(this.form)
            .then((result) => {
              this.loading = false
              if (result.state == 200) {
                this.$message({
                  message: '登陆成功',
                  type: 'success',
                })
                window.localStorage.setItem('token', result.data.token)
                window.localStorage.setItem('user', JSON.stringify(result.data.user))
                if (this.remember) {
                  window.localStorage.setItem('remember', this.remember)
                  window.localStorage.setItem('loginName', this.form.loginName)
                  window.localStorage.setItem('password', this.form.password)
                } else {
                  window.localStorage.removeItem('remember')
                  window.localStorage.removeItem('loginName')
                  window.localStorage.removeItem('password')
                }
                this.$router.push('/dashboard')
              } else {
                this.$message({
                  message: result.message,
                  type: 'error',
                })
              }
            })
            .catch((err) => {
              this.loading = false
              this.$message({
                message: err.message,
                type: 'error',
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#container {
  display: flex;
  justify-content: center;
  align-content: center;
  background: none;
  #titleContainer {
    position: absolute;
    top: 40px;
    z-index: 2;
    #bigTitle {
      cursor: pointer;
      color: #ffffff;
      font-size: 55px;
      font-weight: bold;
      text-align: center;
      .char {
        margin: 0 2px;
        display: inline-block;
        transition-duration: 0.3s;
      }
      .char:hover {
        transform: scale(1.5);
      }
      .space {
        display: inline-block;
        margin: 0 10px;
      }
    }
    #secTitle {
      text-align: center;
      font-size: 25px;
      color: #ffffff;
      margin: 10px 0;
    }
    #thrTitle {
      color: #ffffff;
      text-align: center;
      font-size: 15px;
    }
  }

  .loginBox {
    height: 350px;
    width: 600px;
    position: absolute;
    top: 350px;
    border: none;
    background: none;
    box-shadow: 0 0 15px #174e75;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 2;

    img {
      position: absolute;
      width: 100px;
      z-index: 2;
      top: -50px;
      border-radius: 50%;
      border: 5px solid white;
      transition-duration: 0.3s;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.5);
    }
    #tips {
      position: absolute;
      top: 60px;
      font-weight: bold;
      color: #ffffff;
    }

    .loginForm {
      margin-top: 110px;
      width: 95%;
    }
    input {
      background: transparent;
    }

    .el-button {
      width: 100%;
    }

    .el-checkbox {
      color: #ffffff;
    }
  }

  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
