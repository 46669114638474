import http from './http'

export default {
  login(params) {
    if (params.admin) {
      return http.post('api/login', params)
    } else {
      return http.post('api/login/proxy', params)
    }
  },
}
