import moment from 'moment'
export default {
  format(date, format) {
    return moment(date).format(format)
  },
  getDate() {
    return moment().format('YYYY-MM-DD')
  },
  subDay(days) {
    return moment().subtract(days, 'days').format('YYYY-MM-DD')
  },
  addDay(days) {
    return moment().add(days, 'days').format('YYYY-MM-DD')
  },
  subDayByDate(date, days) {
    return moment(date).subtract(days, 'days').format('YYYY-MM-DD')
  },
  addDayByDate(date, days) {
    return moment(date).add(days, 'days').format('YYYY-MM-DD')
  },
}
