import http from '@/network/http'

export default {
  getAll(params) {
    return http.get('/api/paper', params)
  },
  getPage(currPage, pageCount, params) {
    return http.get(`/api/paper/page/${currPage}/${pageCount}`, params)
  },
  getOne(id) {
    return http.get(`/api/paper/${id}`)
  },
  create(params) {
    return http.post('/api/paper', params)
  },
  update(params) {
    return http.put(`/api/paper/${params.id}`, params)
  },
  delete(id) {
    return http.delete(`/api/paper/${id}`)
  },
  batchCreate(params) {
    return http.post(`/api/paper/batch`, params)
  },
}
