export default {
  dashboard: {
    name: '首页',
    path: 'dashboard',
    closable: false,
  },
  paper: {
    name: '电脑壁纸',
    path: 'paper',
    closable: true,
  },
  mpaper: {
    name: '手机壁纸',
    path: 'mpaper',
    closable: true,
  },
  avatar: {
    name: '个人头像',
    path: 'avatar',
    closable: true,
  },
  404: {
    name: '页面走丢了',
    path: '404',
    closable: true,
  },
  500: {
    name: '服务器生气了',
    path: '500',
    closable: true,
  },
}

const TYPE = [
  { id: 1, name: '科幻', color: '#909399' },
  { id: 2, name: '动漫', color: '#67C23A' },
  { id: 3, name: '风景', color: '#E6A23C' },
  { id: 4, name: '动物', color: '#E6A23C' },
  { id: 5, name: '水墨', color: '#DCDFE6' },
  { id: 6, name: '体育', color: '#1926e2' },
  { id: 7, name: '文字', color: '#c840ff' },
  { id: 8, name: '其他', color: '#F56C6C' },
]

export { TYPE }
