import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/' },
  { path: '/', redirect: '/dashboard' },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '熊猫传媒', // 标题设置
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    meta: {
      title: '熊猫传媒', // 标题设置
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
        meta: {
          title: '熊猫传媒', // 标题设置
        },
      },
      {
        path: '/paper',
        name: 'paper',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Paper'),
        meta: {
          title: '熊猫传媒', // 标题设置
        },
      },
      {
        path: '/mpaper',
        name: 'mpaper',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/MPaper'),
        meta: {
          title: '熊猫传媒', // 标题设置
        },
      },
      {
        path: '/avatar',
        name: 'avatar',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Avatar'),
        meta: {
          title: '熊猫传媒', // 标题设置
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    let token = window.localStorage.getItem('token')
    let currUser = window.localStorage.getItem('user')
    if (!token || !currUser) {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
