import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items: [
      {
        name: '首页',
        path: 'dashboard',
        closable: false,
      },
    ],
    tabItems: [
      {
        name: '首页',
        path: 'dashboard',
        closable: false,
      },
    ],
  },
  mutations: {
    changeItems(state, payload) {
      state.items = payload.items
    },
    changeTab(state, payload) {
      if (state.tabItems.findIndex((item) => item.path == payload.tab.path) >= 0) {
        return
      }
      state.tabItems.push(payload.tab)
    },
    removeTab(state, payload) {
      state.tabItems.splice(payload.index, 1)
    },
    removeAllTab(state, payload) {
      state.tabItems.splice(1, state.tabItems.length - 1)
    },
    removeOtherTab(state, payload) {
      state.tabItems.splice(payload.index + 1, state.tabItems.length - payload.index)
      state.tabItems.splice(1, payload.index - 1)
    },
  },
  actions: {
    changeItems(context, payload) {
      context.commit(payload)
    },
    changeTab(context, payload) {
      context.commit(payload)
    },
    removeTab(context, payload) {
      context.commit(payload)
    },
    removeAllTab(context, payload) {
      context.commit(payload)
    },
    removeOtherTab(context, payload) {
      context.commit(payload)
    },
  },
  modules: {},
})
