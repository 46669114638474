<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
}
</script>

<style lang="scss" scoped>
#app {
  // background: linear-gradient(to right, #2aaeff, #3db5a6);
  background: #2d3a4b;
}
</style>
