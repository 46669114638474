import Vue from 'vue'
import Date from '@/utils/date'
import { TYPE } from '@/utils/constants'

Vue.mixin({
  data() {
    return {
      keyword: '',
      currUser: JSON.parse(window.localStorage.getItem('user')),
      mainData: [],
      page: {
        totalCount: 0,
        currPage: 1,
        pageCount: 50,
        totalPage: 0,
      },
      mainType: TYPE,
      header: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    }
  },
  methods: {
    updateCurrUser(user) {
      this.currUser = user
      window.localStorage.setItem('user', JSON.stringify(user))
    },
    handleSizeChange(val) {
      this.page.pageCount = val
      this.search()
    },
    handleCurrentChange(val) {
      this.page.currPage = val
      this.search()
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 和 PNG 格式!')
      }
      return isJPG || isPNG
    },
  },
  filters: {
    phoneFilter(val) {
      if (!val) {
        return ''
      }
      const reg = /^(\d{3})\d{4}(\d{4})$/
      return val.replace(reg, '$1****$2')
    },
    dateFormat(date, format) {
      if (!date) return ''
      return Date.format(date, format ? format : 'YYYY-MM-DD HH:mm')
    },
  },
})
